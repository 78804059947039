import { Flex } from "@chakra-ui/react";
import { Monaco } from "@monaco-editor/react";
import { editor, IDisposable } from "monaco-editor";
import { useEffect, useRef } from "react";
import { container } from "tsyringe";
import BaseCodeEditor from "../../../components/CodeEditor/BaseCodeEditor";
import { JavascriptSchemaTypesClient } from "../../../lib/javascript-schema-types.client";
import {
  CompletionItem,
  CompletionItemKind,
  EditorContribSuggestController,
  EditorSuggestionWidgetOnDidFocusEvent,
  IRange,
  MarkerSeverity,
  SuggestionConfiguration
} from "../../../types/codeEditor";
import useConfiguredDestinationAliases from "../../destinations/api/useConfiguredDestinationsAliases";
import { useSourceGroups } from "../../source-groups/api/getSourceGroups";
import { ISourceGroupsResponse } from "../../source-groups/types";
import { useSchemas } from "../api/getSchemas";
import {
  IFormatCodeEditorProps,
  ISchemaLink,
  ISchemaListResponse
} from "../types";
import { JavascriptSchemaRootLevelSnippetCompletions } from "../utils/codeEditorSnippets";

const JavascriptSchemaCodeEditor = ({
  onEditorMount,
  value,
  onValueChange,
  onValidationChange,
  diffComparison,
  leftSideBottomBarElements,
  size,
  rightSideBottomBarElements,
  bottomBarActionButtons,
  options
}: IFormatCodeEditorProps) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const sourceGroupsHook = useSourceGroups();
  const schemasHook = useSchemas();
  const destinationConfigurationsHook = useConfiguredDestinationAliases();

  const AUTH_REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI as string;

  let completionProvider: IDisposable | null = null;
  useEffect(() => {
    return () => {
      completionProvider?.dispose();
    };
  }, [completionProvider]);

  const isLoading = [
    sourceGroupsHook,
    schemasHook,
    destinationConfigurationsHook
  ].some((s) => s.isLoading);
  if (isLoading) {
    return null;
  }

  const initSuggestionsConfigurationMap = (
    sourceGroups: ISourceGroupsResponse[],
    schemas: ISchemaListResponse[],
    destinationConfigurationAliases: string[]
  ) => {
    return [
      // Source groups
      {
        patterns: [`.triggers[(]$`, `.sourceGroup[(]$`],
        suggestions: sourceGroups.map((sg) => ({
          label: `(Source group) ${sg.name}`,
          insertText: `'${sg.alias}'`,
          kind: CompletionItemKind.Text
        }))
      },
      // Normal schemas
      {
        patterns: [`.reprocess[(]$`, `.reference[(]$`],
        suggestions: schemas
          .filter((s) => s.type === "normal")
          .map((s) => ({
            label: `(Schema) ${s.name}`,
            insertText: `'${s.viewHandle}'`,
            kind: CompletionItemKind.Text
          }))
      },
      // Partial schemas
      {
        patterns: [`.partial[(]$`],
        suggestions: schemas
          .filter((s) => s.type === "partial")
          .map((s) => ({
            label: `(Schema) ${s.name}`,
            insertText: `'${s.viewHandle}'`,
            kind: CompletionItemKind.Text
          }))
      },
      // Destinations
      {
        patterns: [`.destination[(]$`],
        suggestions: destinationConfigurationAliases.map((alias) => ({
          label: `(Destination) ${alias}`,
          insertText: `'${alias}'`,
          kind: CompletionItemKind.Text
        }))
      }
    ];
  };

  const suggestionConfigurations = initSuggestionsConfigurationMap(
    sourceGroupsHook?.data ?? [],
    schemasHook?.data ?? [],
    destinationConfigurationsHook?.data?.map((dto) => dto.alias) ?? []
  );

  const setMonacoJavascriptDefaults = async (monaco: Monaco) => {
    monaco.languages.typescript.javascriptDefaults.setEagerModelSync(true);
    monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
      noSemanticValidation: false,
      noSyntaxValidation: false,
      diagnosticCodesToIgnore: [7044]
    });
    monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
      allowJs: true,
      allowNonTsExtensions: true,
      target: monaco.languages.typescript.ScriptTarget.ESNext,
      strict: true,
      checkJs: true,
      noImplicitAny: false,
      module: monaco.languages.typescript.ModuleKind.ESNext
    });

    const typeDefinitionsResponse = await container
      .resolve(JavascriptSchemaTypesClient)
      .get();
    const typesFileName = "enterspeed.d.ts";
    monaco.languages.typescript.javascriptDefaults.addExtraLib(
      typeDefinitionsResponse ?? ``,
      typesFileName
    );
  };

  const setEditorSnippetAndInsertionSuggestions = (
    codeEditorInstance: editor.IStandaloneCodeEditor,
    monaco: Monaco
  ) => {
    const suggestController = codeEditorInstance.getContribution?.(
      "editor.contrib.suggestController"
    ) as EditorContribSuggestController | undefined | null;
    suggestController?.widget?.value?._setDetailsVisible?.(true);
    suggestController?.widget?.value?.onDidFocus(
      async (event: EditorSuggestionWidgetOnDidFocusEvent) => {
        await event.item._resolveCache;
        event.model._items.forEach((item) => {
          const completion = JavascriptSchemaRootLevelSnippetCompletions.find(
            (f) => f.alias === item.textLabel
          );
          if (
            completion &&
            item.completion.kind === CompletionItemKind.Field &&
            (item.completion.documentation?.value?.includes(
              "@label SCHEMA_FUNCTION"
            ) ??
              false)
          ) {
            item.completion.insertText = completion.snippet;
          }
        });
      }
    );

    // Set suggestions
    completionProvider = monaco.languages.registerCompletionItemProvider(
      "javascript",
      {
        triggerCharacters: ["("],
        provideCompletionItems: (model, position) => {
          const textUntilPosition = model.getValueInRange({
            startLineNumber: 1,
            startColumn: 1,
            endLineNumber: position.lineNumber,
            endColumn: position.column
          });
          const match = suggestionConfigurations.find((f) =>
            f.patterns.some((pattern) => textUntilPosition.match(pattern))
          );
          if (!match) {
            return { suggestions: [] };
          }
          const word = model.getWordUntilPosition(position);
          const range: IRange = {
            startLineNumber: position.lineNumber,
            endLineNumber: position.lineNumber,
            startColumn: word.startColumn,
            endColumn: word.endColumn
          };
          return {
            suggestions: createDependencyProposals(match, range)
          };
        }
      }
    );

    // Schema links and schema warnings
    completionProvider = monaco.languages.registerLinkProvider("javascript", {
      provideLinks(model) {
        const schemas = schemasHook?.data ?? [];

        // Matches patterns like `context.partial('schemaAlias', {})`, `context.reference("schemaAlias")` or `context.reprocess("schemaAlias")`
        // and finds the schema alias.
        const regex =
          /context[ \t\n]*\.[ \t\n]*(?:partial|reference|reprocess)\(['"`]([^$\n]*?)['"`][,)]/g;
        const editorValue = model.getValue();
        const matches = [...editorValue.matchAll(regex)];

        const schemaLinks = matches.map((match) => {
          const startIndex = (match.index ?? 0) + match[0].indexOf(match[1]);

          const lineNumber = editorValue
            .substring(0, startIndex)
            .split("\n").length;
          const startIndexForLine = editorValue
            .substring(0, startIndex)
            .lastIndexOf("\n");

          return {
            schemaAlias: match[1],
            startLineNumber: lineNumber,
            startColumn: startIndex - startIndexForLine,
            endLineNumber: lineNumber,
            endColumn: startIndex + match[1].length - startIndexForLine
          } as ISchemaLink;
        });

        const [
          schemaLinksWithMatchingSchema,
          schemaLinksWithoutMatchingSchema
        ] = schemaLinks.reduce(
          ([withMatch, withoutMatch], schemaLink) => {
            const { schemaAlias } = schemaLink;
            return schemas.some(({ viewHandle }) => viewHandle === schemaAlias)
              ? [[...withMatch, schemaLink], withoutMatch]
              : [withMatch, [...withoutMatch, schemaLink]];
          },
          [[] as ISchemaLink[], [] as ISchemaLink[]]
        );

        // Set marker warnings for missing schema aliases
        if (editorRef.current && monaco) {
          monaco.editor.setModelMarkers(
            model,
            "owner",
            schemaLinksWithoutMatchingSchema.map((schemaLink) => {
              return {
                startLineNumber: schemaLink.startLineNumber,
                startColumn: schemaLink.startColumn,
                endLineNumber: schemaLink.endLineNumber,
                endColumn: schemaLink.endColumn,
                message: `No schema with alias '${schemaLink.schemaAlias}' exists on the tenant. Note schema aliases are case sensitive.`,
                severity: monaco.MarkerSeverity.Warning
              };
            })
          );
        }

        // Set links for matching schema aliases
        const links = schemaLinksWithMatchingSchema.map((schemaLinks) => {
          return {
            tooltip: "Go to schema",
            url: `${AUTH_REDIRECT_URI}schemas/${schemaLinks.schemaAlias}`,
            range: {
              startLineNumber: schemaLinks.startLineNumber,
              startColumn: schemaLinks.startColumn,
              endLineNumber: schemaLinks.endLineNumber,
              endColumn: schemaLinks.endColumn
            }
          };
        });

        return {
          links: links
        };
      }
    });
  };

  const editorMounted = async (
    editorInstance: editor.IStandaloneCodeEditor | editor.IStandaloneDiffEditor,
    monaco: Monaco
  ) => {
    await setMonacoJavascriptDefaults(monaco);

    if (editorInstance.getEditorType() === `vs.editor.ICodeEditor`) {
      const codeEditorInstance = editorInstance as editor.IStandaloneCodeEditor;
      setEditorSnippetAndInsertionSuggestions(codeEditorInstance, monaco);
    }
    onEditorMount?.(editorInstance, monaco);
  };

  const createDependencyProposals = (
    configuration: SuggestionConfiguration,
    range: IRange
  ): CompletionItem[] => {
    return configuration.suggestions.map((s) => ({
      ...s,
      range
    }));
  };

  const validationChanged = (markers: editor.IMarker[]) => {
    onValidationChange?.(
      markers.filter((marker) => marker.severity === MarkerSeverity.Error)
    );
  };

  return (
    <>
      <Flex w={"100%"} ref={editorRef} direction={"column"}>
        <BaseCodeEditor
          value={value}
          options={{
            ...options,
            snippetSuggestions: "none",
            quickSuggestions: true,
            lightbulb: {
              enabled: false
            }
          }}
          size={size}
          language="javascript"
          onEditorMount={editorMounted}
          onValidationChange={validationChanged}
          onValueChange={onValueChange}
          leftSideBottomBarElements={leftSideBottomBarElements}
          rightSideBottomBarElements={rightSideBottomBarElements}
          bottomBarActionButtons={bottomBarActionButtons}
          diffComparison={diffComparison}
        ></BaseCodeEditor>
      </Flex>
    </>
  );
};

export default JavascriptSchemaCodeEditor;

import { MonacoMarker } from "monaco-editor";
import {
  ErrorDescriptor,
  ICodeEditorCommonProps,
  ObjectResult
} from "../../../types/codeEditor";
import { IEnvironmentClientResponse } from "../../environment-clients/types";
import { ITenantId } from "../../tenants/types";

export interface ISchemaListResponse {
  id: ISchemaResponseId;
  latestFormat: SchemaFormat | null;
  latestVersion: number;
  name: string;
  tenantId: ITenantId;
  type: SchemaType;
  viewHandle: string;
}

export interface ISchemaSearchListResponse extends ISchemaListResponse {
  hits: string[];
}

export interface ISchemaFolderListItem {
  originalName: string;
  name: string;
  viewHandle: string;
  mappingSchemaGuid: string;
  id: ISchemaResponseId;
}
export interface ISchemaResponseId {
  idValue: string;
  mappingSchemaGuid: string;
  version?: number;
}

export type SchemaFormat = "json" | "javascript";
export type SchemaType = "normal" | "partial" | "collection";

export interface ISchemaDeliveryResponse {
  environmentClients: IEnvironmentClientResponse[];
  route: {
    url: string | null;
    handles: string[];
  };
}

export interface ISchemaNode {
  originalName: string;
  name: string;
  viewHandle: string;
  mappingSchemaGuid: string;
  id: ISchemaResponseId;
  hasChildren: boolean;
  children: ISchemaNode[];
  isFolderOpen?: boolean;
  latestFormat: null | SchemaFormat;
  latestVersion: number;
  type: null | SchemaType;
  folderPath?: string;
}

export type CreateSchemaModalRequest = {
  format: SchemaFormat;
  viewHandle: string;
  type: SchemaType;
  name: string;
  data?: string;
};

export type DuplicateSchemaModalRequest = {
  name: string;
  viewHandle: string;
};

export interface ICreateSchemaModal {
  name?: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (
    type: SchemaListModalType.CREATE,
    id?: string,
    name?: string
  ) => void;
  onSubmit?: (
    request: CreateSchemaModalRequest
  ) => Promise<SchemaActionModelSubmissionResult>;
}

export interface IDuplicateSchemaModalProps {
  sourceSchemaName: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onSubmit: (
    event: DuplicateSchemaModalRequest
  ) => Promise<SchemaActionModelSubmissionResult>;
}

export interface IGoToVersionModal {
  versions: ISchemaVersionDetails[];
  currentVersion: number;
  isOpen: boolean;
  onClose: () => void;
}
export interface ISchemaCreatePayload {
  format: SchemaFormat;
  viewHandle: string;
  type: SchemaType;
  name: string;
  data?: string;
}

export type CreateSchemaActionModelSubmissionRequest = {
  modalType: SchemaListModalType.CREATE;
  format: SchemaFormat;
  viewHandle: string;
  type: SchemaType;
  name: string;
  data?: string;
};

export type DuplicateSchemaActionModelSubmissionRequest = {
  modalType: SchemaListModalType.DUPLICATE;
  schemaGuid: string;
  viewHandle: string;
  name: string;
};

export type DeleteSchemaActionModelSubmissionRequest = {
  modalType: SchemaListModalType.DELETE;
  schemaGuid: string;
};

export type EditSchemaActionModelSubmissionRequest = {
  modalType: SchemaListModalType.EDIT;
  name: string;
  schemaGuid: string;
};

export type SchemaActionModelSubmissionRequest =
  | CreateSchemaActionModelSubmissionRequest
  | DuplicateSchemaActionModelSubmissionRequest
  | DeleteSchemaActionModelSubmissionRequest
  | EditSchemaActionModelSubmissionRequest;

export interface SchemaActionModelSubmissionResult {
  valid: boolean;
  mappingSchemaGuid?: string;
}

export enum SchemaListModalType {
  CREATE = "Create",
  DELETE = "Delete",
  EDIT = "Edit",
  DUPLICATE = "Duplicate"
}

export enum SchemaModalType {
  DELETE = "Delete",
  EDIT = "Edit",
  GO_TO_VERSION = "Go to version"
}

export interface ISchemaEditPayload {
  name: string;
}

export interface ISchemaVersionEditPayload {
  format: SchemaFormat;
  schema: string;
}

export interface ISchemaDuplicatePayload {
  schemaGuid: string;
  name: string;
  viewHandle: string;
}

export interface ISchemaResponse {
  name: string;
  id: ISchemaResponseId;
  version: ISchemaVersion;
  versions: ISchemaVersionDetails[];
  latestVersion: number;
  viewHandle: string;
  deployments: ISchemaDeployment[];
  type: SchemaType;
}

export interface ISchemaVersion {
  data: string;
  format: SchemaFormat;
  id: ISchemaResponseId;
  isEditable: boolean;
}

export interface ISchemaVersionDetails {
  id: ISchemaResponseId;
  updatedAt: string;
}

export interface ISchemaDeployment {
  environmentId: string;
  version: number | null;
  sourceIds: string[];
}

export interface ISchemaVersionFormat {
  preProcessValue(schemaVersionData: string): string;
  postProcessValue(schemaVersionData: string): unknown;

  canHandle(schemaFormat: SchemaFormat): boolean;
}

export interface ISchemaEvaluationService {
  evaluate(
    type: SchemaType,
    format: SchemaFormat,
    schemaVersionData: string,
    tenantConfig: ISchemaVersionFormatEvaluationTenantConfig
  ): ObjectResult<IEvaluatedSchemaMetadata>;
}

export interface IEvaluatedSchemaMetadata {
  sourceEntityTypes: string[];
}

export interface IFormatCodeEditorProps extends ICodeEditorCommonProps {
  onValidationChange?: (markers: MonacoMarker[]) => void;
}

export interface IJsonFormatCodeEditorProps extends IFormatCodeEditorProps {
  schemaType: SchemaType;
}

export interface ISchemaVersionValidityState {
  valid: boolean;
  errors?: ErrorDescriptor[];
}

export interface ISchemaValidationErrorsBottomBarButtonProps {
  errors?: ErrorDescriptor[];
}

export interface ISchemaPendingChangesBottomBarButtonProps {
  hasPendingChanges: boolean;
}

export interface IEditorAutoSaveBottomBarButtonProps {
  enabled: boolean;
  onToggle(): void;
}

export interface ISchemaVersionMetadataState {
  editable: boolean;
  sourceEntityTypes: string[];
}

export interface IJsonSourceEntityTypesSchema {
  viewHandle: string;
  sourceEntityTypes: string[];
  environments: { environmentId: string; sourceIds: string[] }[];
}

export interface IJsonSourceGroupTriggerSchema {
  triggers: { [sourceGroupAlias: string]: string[] };
}

export interface ISchemaVersionFormatEvaluationTenantConfig {
  isUsingSourceGroups: boolean;
}

export interface ISchemaLink {
  schemaAlias: string;
  startLineNumber: number;
  startColumn: number;
  endLineNumber: number;
  endColumn: number;
}

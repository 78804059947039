import { RouterProvider } from "react-router-dom";

import useLatestAppToast from "./features/appVersion/useLatestAppToast";
import router from "./router";

export default function App() {
  useLatestAppToast();

  return <RouterProvider router={router}></RouterProvider>;
}
